.app-container {
  margin: auto;
  text-align: center;
  padding: 5px;

  .info-toggle, .stat-toggle {
    transition: scale .15s ease;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    color: #000;
    width: 35px;
    height: 35px;
    padding: 10px;
    box-sizing: content-box;

    img {
      width: 20px;
    }

    &:active {
      scale: 90%;
    }
  }

  .stat-toggle {
    left: 0;
    right: auto;
  }

  header {
    font-size: 48px;
    margin-top: 40px;
  }

  .workspace {
    margin-top: 12px;

    &--row {
      display: flex;
      justify-content: center;

      .tile {
        align-self: center;
        background: rgba(255, 255, 255, 0.85);
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 10px 15px;
        font-weight: bold;
        padding: 5px;
        box-sizing: border-box;

        &.active {
          box-shadow: 0px 0px 6px 6px rgba(0,24,238,.5);
        }

        div {
          vertical-align: middle;
        }
      }
    }

    &--row:not(:first-child) > .tile.number:nth-child(1) {
      background: rgba(255, 255, 255, .45);
      border: solid 1px #000;
    }

    .target-container {
      position: relative;
      width: 320px;
      font-size: 50px;
      margin: 10px auto 0;
      height: 75px;

      .final-sum {
        color: #DB6262;
        left: 0;
        transition: all 0.5s ease;
        padding-right: 10px;
        width: 150px;
        position: absolute;

        &.empty {
          position: absolute;
          left: -1000px;
        }

        &:not(.empty) + .target {
          right: 0;
        }

        div {
          vertical-align: middle;
        }
      }

      .target {
        position: absolute;
        transition: all 0.5s ease;
        width: 150px;
        padding-left: 10px;
        right: 85px;

        &.gold {
          color: rgb(232 185 32 / 50%);
          background: #222 -webkit-gradient(linear, left top, right top, from(#333), to(#333), color-stop(0.45, #fff)) 0 0 no-repeat;
        	-webkit-background-size: 150px;
        	-webkit-background-clip: text;
        	-webkit-animation-name: shine;
        	-webkit-animation-duration: 6s;
        	-webkit-animation-iteration-count: infinite;
          animation: shine 6s infinite;
        	text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
        }
      }
    }
  }

  .number {
    width: 55px;
    height: 55px;
    font-size: 24px;
  }

  .operator {
    width: 45px;
    height: 45px;
    font-size: 24px;
  }

  .controls {
    margin-top: 5px;

    .number-control, .operator-control {
      display: flex;
      justify-content: center;
    }

    button {
      color: #000;
      transition: scale 0.1s ease;
      align-items: center;
      background: #FFF;
      border: solid 2px #000;
      border-radius: 5px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px 4px;
      font-family: "Cabinet", sans-serif;
      font-weight: bold;
      padding: 0;

      &:active {
        scale: 94%;
      }

      &.operator {
        margin: 10px 6px;
      }

      &.used {
        background: #ccc;
      }
    }
  }

  .modal-container, .info-container {
    transition: all 0.5s ease;
    position: fixed;
    top: -2000px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    z-index: 2;

    &.show {
      -webkit-animation-name: showmodal;
      -webkit-animation-duration: 1s;
      -webkit-animation-iteration-count: 1;
      animation: showmodal 1s 1;
      top: 0;
    }
  }

  .info-container {
    text-align: left;
    font-weight: bold;

    .header {
      font-size: 28px;
      font-weight: 500;
      margin-top: 20px;
    }

    .sub-header {
      font-size: 18px;
      margin-top: 15px;
    }

    ul {
      margin: 20px 0;
      padding: 0 15px;

      li {
        margin-top: 5px;
      }
    }

    .notes {
      border-top: solid 1px #000;
      text-align: center;
      padding-top: 20px;
      margin-top: 30px;
    }
  }

  .modal {
    transition: all 0.5s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    width: 85%;
    max-width: 500px;
    padding: 18px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);

    .heading {
      font-size: 36px;
    }

    .stats {
      font-weight: bold;
      column-count: 3;
      column-gap: 10px;
      margin-top: 20px;

      .stat {
        width: 100%;
        font-size: 25px;
        display: inline-block;
      }
    }

    .next-timer {
      font-size: 20px;
      font-weight: bold;
      margin-top: 30px;
    }

    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      padding: 8px 20px;
      font-size: 30px;
      font-weight: bold;
    }

    .actions, .next-timer {
      margin-top: 30px;
      position: relative;

      button {
        color: #000;
        transition: scale 0.1s ease;
        border: solid 2px #000;
        border-radius: 16px;
        padding: 6px 20px 5px;
        font-family: "Cabinet", sans-serif;
        font-weight: bold;
        font-size: 16px;
        background: #fff;
        margin: 0;
        width: 135px;

        &:active {
          scale: 94%;
        }

        &.try-again {
          margin-right: 4px;
        }

        &.share {
          margin-left: 4px;
        }

        &.share, &.play, &.refresh {
          color: #fff;
          background: #547aff;
          border: solid 2px #0f31a7;
        }

        &.play, &.refresh {
          display: block;
          margin: auto;
          width: 200px;
        }

        &.refresh {
          margin-top: 10px;
        }
      }

      .share-wrap {
        display: inline-block;
        position: relative;
      }

      .copied {
        position: absolute;
        top: -10px;
        right: -15px;
        rotate: 25deg;
        opacity: 0;

        &.show {
          -webkit-animation-name: copied;
          -webkit-animation-duration: 1s;
          -webkit-animation-iteration-count: 1;
          animation: copied 1s 1;
        }
      }
    }
  }
}

@keyframes copied {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes shine {
	0% {
		background-position: -50px;
	}
	30% {
		background-position: top left;
	}
	70% {
		background-position: top right;
	}
	100% {
		background-position: -50px;
	}
}

@keyframes showmodal {
	0% {
		top: -2000px;
	}
	20% {
		top: -2000px;
	}
  40% {
    top: 20px;
	}
  50% {
    top: -10px;
  }
  60% {
    top: 5px;
  }
	90% {
		top: 0px;
	}
  100% {
		top: 0px;
	}
}
