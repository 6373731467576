/* Cabinet */
@font-face {
  font-family: "Cabinet";
  src: url('../fonts/cabinet/CabinetGrotesk-Bold.eot');
  src: url('../fonts/cabinet/CabinetGrotesk-Bold.eot') format('embedded-opentype'),
       url('../fonts/cabinet/CabinetGrotesk-Bold.woff2') format('woff2'),
       url('../fonts/cabinet/CabinetGrotesk-Bold.woff') format('woff'),
       url('../fonts/cabinet/CabinetGrotesk-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Cabinet";
  src: url('../fonts/cabinet/CabinetGrotesk-Black.eot');
  src: url('../fonts/cabinet/CabinetGrotesk-Black.eot') format('embedded-opentype'),
       url('../fonts/cabinet/CabinetGrotesk-Black.woff2') format('woff2'),
       url('../fonts/cabinet/CabinetGrotesk-Black.woff') format('woff'),
       url('../fonts/cabinet/CabinetGrotesk-Black.ttf') format('truetype');
  font-weight: black;
  font-style: normal;
}

html, body {
  margin: 0;
  min-height: 100%;
  width: 100%;
  font-family: "Cabinet", serif;
  font-weight: black;
  overflow-x: hidden;
}

body {
  background: rgb(84,122,255);
  background: linear-gradient(180deg, rgba(84,122,255,1) 0%, rgba(152,230,255,0.8) 100%);
}

button {
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}
